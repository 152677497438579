import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { CartContext } from '../../../contexts/CartContext';
import CartProducts from './CartProducts';
import CloseBt from '../../../assets/img/icons/close.svg';
import "./cart.scss";

const { REACT_APP_CARTEMPTY, REACT_APP_CART_OK, REACT_APP_SHOW } = process.env;

const Cart = () => {
    const { cartItems, checkout } = useContext(CartContext);
    const hideCart = () => {
        document.getElementsByTagName('html')[0].classList.toggle('show-cart');
    }
    const RenderSuccess = () => {
        return (
            <div className="success message h-100 d-flex align-items-center justify-content-center">
                <h2 className="bigger" dangerouslySetInnerHTML={{ __html: REACT_APP_CART_OK }} />
            </div>
        );
    };

    return(
        <>
            <aside className="cart">
                <div className={`container p-0`}>
                    { 
                    /* Close BTN */ 
                    }
                    <div className="btn btn_rotate btn_close">
                        <img width="20px" onClick={ hideCart } src={ CloseBt } alt="Fermer" />
                    </div>
                    
                    {
                        /////////////////////////////////////////////////
                        // CHECKOUT // Success message
                        /////////////////////////////////////////////////
                        checkout ?
                             <RenderSuccess />
                             :
                            // Cart
                            cartItems?.length > 0 ?
                            // Cart with products
                            <>
                                <CartProducts/>
                            </>
                            :
                            // Empty Cart
                            <div className="d-flex align-items-center justify-content-center text-center h-100">
                                <div>
                                    <p>{ REACT_APP_CARTEMPTY }</p>
                                    <p><NavLink onClick= { hideCart } className="btn btn_hoverBg" to='/products'>{ REACT_APP_SHOW }</NavLink></p>
                                </div>
                            </div>
                    }
                </div>
            </aside>
            { 
            /* Cart COVER */ 
            }
            <div className="cart_cover" onClick= { hideCart }></div>
        </>
    )
}
export default Cart;