import React from 'react';
import FormField from "./FormField";
import { Countries } from '../../../data/countries-fr';
import Icon from '../../../assets/img/icons/creditcard.svg';

const BillingDetailsFields = () => {
  return (
    <>
      <div className="text-center">
        <img width="80px" src={ Icon } alt="Credit card" />
        <h2 className="big pb-4">Effectuez votre réglement<br/>en toute sécurité</h2>
      </div>
      <FormField
        name="name"
        label="Name"
        type="text"
        placeholder="Nom Prénom"
        required
      />
      <FormField
        name="email"
        label="Email"
        type="email"
        placeholder="email"
        required
      />
      <FormField
        name="postal_address"
        label="Address"
        type="text"
        placeholder="Adresse postale"
        required
      />
      <FormField
        name="zip"
        label="ZIP"
        type="text"
        placeholder="Code postal"
        required
      />
      <FormField
        name="city"
        label="City"
        type="text"
        placeholder="Ville"
        required
      />
      <label className="d-block pt-2 ">Pays</label>
      <select name="state" defaultValue="FR">
        { 
          Countries?.countries[0] && Object.keys(Countries.countries[0]).map((key) => {
            return  <option key={key}  value={ key }  >{Countries.countries[0][key]}</option>
          })
        }
      </select>
    
      
    </>
  );
};
export default BillingDetailsFields;