import React, { useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CartContext } from '../../../contexts/CartContext';
import CheckoutForm from './CheckoutForm';
import CartItem from './CartItem';

const { REACT_APP_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_PUBLISHABLE_KEY);

const CartProducts = () => {
    const { cartItems, total } = useContext(CartContext);


    return (
        <div id="cart__items" className="h-100" >
            <h2 className="bigger text-center">Votre commande</h2>
            <Elements stripe={ stripePromise }>
            {   
                cartItems.map(product =>  
                    <CartItem key={product.id} product={product}/>
                )
            }
            <CheckoutForm
                total={total}
            />
           </Elements>
        </div>
    );
}
export default CartProducts;