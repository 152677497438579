import React from "react";
import Feed from "react-instagram-authless-feed";
import Plx from "react-plx";
import { NavLink } from "react-router-dom";
import BannerImage from "../../assets/img/banner_image.jpg";
import ProfileImage from "../../assets/img/profile.jpg";
import InstaLogo from "../../assets/img/icons/insta.svg";
import "./footer.scss";
const { REACT_APP_PRESENTATIONTXT, REACT_APP_COPYRIGHT } = process.env;
// import BackgroundVideo from '../components/BackgroundVideo/BackgroundVideo';
// import Video from '../../assets/videos/video.mp4';

// const video = require(`videos/video.mp4`).default;
/* 
<div id="video">
    <BackgroundVideo video={ Video } ></BackgroundVideo>
</div>
*/
const parallaxData = [
    {
        start: "#footer",
        end: ".footer__end",
        properties: [
            {
                startValue: 0,
                endValue: 1,
                property: "opacity",
            },
            {
                startValue: -400,
                endValue: 0,
                property: "marginTop",
            },
        ],
    },
];
const Footer = () => {
    return (
        <>
            <div id="footer">
                <Plx className="parallaxItem" parallaxData={parallaxData}>
                    <div
                        className="banner_image"
                        style={{ backgroundImage: `url( ${BannerImage} )` }}
                    ></div>
                    <div className="footer_container row align-items-end w-100 mx-0 pt-5 my-5">
                        <div className="footer_links col-12 col-lg-3">
                            <ul className="m-0 pb-5">
                                <li>
                                    <NavLink className="nav-link" to="/about">
                                        A propos de nous
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/mentions-legales"
                                    >
                                        Mentions légales
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/conditions-generales-de-vente"
                                    >
                                        Conditions générales de vente
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="nav-link"
                                        to="/gestion-des-cookies"
                                    >
                                        Gestion des cookies
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer_text col-12 col-lg-4 offset-lg-1 my-5 px-5 my-lg-0 px-lg-0">
                            <p
                                className="small m-0 px-3 px-lg-0"
                                dangerouslySetInnerHTML={{
                                    __html: REACT_APP_PRESENTATIONTXT,
                                }}
                            />

                            <Feed
                                userName="k.i.s.me"
                                className="Feed"
                                classNameLoading="Loading"
                                limit={12}
                            />
                            <a
                                href="https://www.instagram.com/k.i.s.me/?hl=fr"
                                target="_blank"
                                title="Instagram Véronique CHOUVET / k.i.s.me"
                                rel="noreferrer"
                            >
                                <img
                                    src={InstaLogo}
                                    width="50px"
                                    height="50px"
                                    alt="Instagram"
                                    className="d-block ml-auto mt-3"
                                />
                            </a>
                        </div>
                        <div className="col-12 col-lg-3 offset-lg-1 p-0 text-left">
                            <img
                                className="responsive-image"
                                src={ProfileImage}
                                alt="Véronique CHOUVET"
                            />
                            <p className="mr-2 pt-1 text-right">
                                {REACT_APP_COPYRIGHT} {new Date().getFullYear()}{" "}
                            </p>
                        </div>
                    </div>
                </Plx>
            </div>
            <div className="footer__end"></div>
        </>
    );
};
export default Footer;
