import React, { useRef } from "react";
import { NavLink } from "react-router-dom";

import CartBtn from "../components/CartBtn/CartBtn";
import Logo from "../../assets/img/logo.svg";
import './nav.scss';
// import IconBurger from '../../assets/img/icons/menu.svg';
// import IconClose from '../../assets/img/icons/close.svg';

const Nav = (props) => {
    // const [ showMenu, setShowMenu ] = useState(false);
    let navbarNav = useRef(null);

    const showCart = () => {
        document.getElementsByTagName("html")[0].classList.toggle("show-cart");
    };
    /*
    const handleShowMenu = () => {
        document.getElementsByTagName('html')[0].classList.toggle('show-navbar');
        setShowMenu(true);
    }
   
    const handleHideMenu = () => {
        document.getElementsByTagName('html')[0].classList.toggle('show-navbar');
        setShowMenu(false);
    }
     */
    // const IconMenu = showMenu === false ? IconBurger : IconClose;

    return (
        <nav className="navbar fixed-top">
            <div className="container-fluid">
                <div ref={navbarNav} className="navbar-nav ">
                    {/* 
                    <NavLink className={`nav-link mr-0 mr-lg-5`} to="/products">
                        Produits
                    </NavLink>
                    */}
                    <div className="navbar-brand">
                        <NavLink exact to="/">
                            <img
                                className="img-responsive"
                                width="150px"
                                src={Logo}
                                alt="Charlieprod - Logo"
                            />
                        </NavLink>
                    </div>

                    {/* <NavLink className="nav-link" to="/about">
                        A propos
                    </NavLink> */}
                </div>

                <div className="navbar-sidebar" onClick={showCart}>
                    <CartBtn />
                </div>
                {/*
                
                <div className="navbar-burger" onClick={ showMenu === false ? handleShowMenu : handleHideMenu }>
                    <img width="40px" src={ IconMenu } alt="Menu" />
                </div>
                */}
            </div>

            {/*
            <div className="container-fluid d-flex align-items-center justify-content-center justify-content-lg-start">
                <div className="navbar-brand">
                    <NavLink exact to="/"><img className="img-responsive" width="150px" src={ Logo } alt="Charlieprod - Logo" /></NavLink>                
                </div>
                <div ref={ navbarNav } className="navbar-nav d-none d-lg-block ml-lg-4" onClick={ handleHideMenu }>
                    <NavLink className={`nav-link`}  to="/products">Produits</NavLink >
                    <NavLink className="nav-link" to="/about">A propos</NavLink >
                </div>
                <div className="navbar-sidebar" onClick={ showCart }>
                    <CartBtn  />
                </div>
                <div className="navbar-burger" onClick={ showMenu === false ? handleShowMenu : handleHideMenu }>
                    <img width="40px" src={ IconMenu } alt="Menu" />
                </div>
            </div>
            */}
        </nav>
    );
};
export default Nav;
