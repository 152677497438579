import React, { useState, useContext } from "react";
import { CartContext } from "../../../contexts/CartContext";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

import IconSecurity from "../../../assets/img/icons/security.svg";
import { formatNumber } from "../../utils/utils";
import BillingDetailsFields from "./BillingDetailsFields";
import CheckoutError from "./CheckoutError";
const { REACT_APP_SERVER } = process.env;

const CheckoutForm = (props) => {
    const { total } = props;
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkoutError, setCheckoutError] = useState();
    const { cartItems, itemCount, handleCheckout } = useContext(CartContext);
    const stripe = useStripe();
    const elements = useElements();

    const handleCardDetailsChange = (ev) => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    const handleFormSubmit = async (ev) => {
        ev.preventDefault();
        setIsProcessing(true);
        
        const billing_details = {
            name: ev.target.name.value,
            email: ev.target.email.value,
            address: {
                line1: ev.target.postal_address.value,
                city: ev.target.city.value,
                country: ev.target.state.value,
                postal_code: ev.target.zip.value,
                state: null,
            }
        };
        // const products_array =  cartItems.map(item => `${item.title} (#${item.id}), quantité : ${item.quantity}, ${formatNumber(item.price)}€`);
        const getProducts =  cartItems.reduce((acc, item) => `${acc}${item.title} (#${item.id}), quantité : ${item.quantity}, ${formatNumber(item.price)}|` ,'');
        
        if (!stripe || !elements) {
            return;
        }

        // Create Payment Method
       const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: billing_details,
            
        });
        
        // Error message
        if (error) {
            setCheckoutError(error.message);
        }
        // Create payment intent
        else {

            // Send post to API
            const { data } = await axios.post(
                `${REACT_APP_SERVER}/api/stripe/create-payment-intent`,
                {
                    amount: total * 100,
                    metadata: {
                      product_nb: itemCount,
                      products:  getProducts
                  }
                }
            );

            // Stripe confirmCardPayment
            await stripe
                .confirmCardPayment(data.client_secret, {
                    payment_method: paymentMethod.id,
                })
                .then(function (result) {
                    // Stripe confirmCardPayment error
                    if (result.error) {
                        setCheckoutError(result.error.message);
                    }
                    // Stripe confirmCardPayment OK
                    else {
                        // Change state
                       handleCheckout();

                        // Add succeeded class
                        document
                            .getElementsByTagName("html")[0]
                            .classList.add("succeeded");

                         // API post : DB write
                        axios.post(
                            `${REACT_APP_SERVER}/api/stripe/db-write`,
                            {
                                result,
                                billing_details,
                                products: getProducts
                            }
                        );
                    }
                });
        }
    };
    const cardElementOpts = {
        iconStyle: "solid",
        hidePostalCode: true,
    };

    const renderForm = () => {
        return (
            <form method="post" onSubmit={handleFormSubmit}>
                {/* Show TOTAL */}
                <p className="text-center price">
                    <span className="price bigger">
                        Total : {formatNumber(total)}
                    </span>
                </p>

                {/* Show Cart details */}
                <BillingDetailsFields />

                {/* Show paymeny module */}
                <div>
                    <CardElement
                        options={cardElementOpts}
                        onChange={handleCardDetailsChange}
                    />
                </div>

                {checkoutError && (
                    <CheckoutError>{checkoutError}</CheckoutError>
                )}

                {/* Submit BT */}
                <button
                    className="d-flex align-items-center"
                    disabled={isProcessing || !stripe}
                >
                    <img
                        className="mr-2"
                        src={IconSecurity}
                        width="20px"
                        height="20px"
                        alt="Paiement sécurisé"
                    />{" "}
                    {isProcessing
                        ? "En cours..."
                        : `Payer ${formatNumber(total)}`}
                </button>
            </form>
        );
    };

    return (
        <div className="checkout-form h-100">
            {renderForm()}
        </div>
    );
};

export default CheckoutForm;