import React, { createContext } from 'react';
import useFetch from '../shared/utils/useFetch';
export const ProductsContext = createContext()

const { REACT_APP_JSON } = process.env;

const ProductsContextProvider = ({children}) => {
    const products = useFetch(`${ REACT_APP_JSON }products`);
    
    return ( 
        <ProductsContext.Provider value={{products}} >
            { children }
        </ProductsContext.Provider>
     );
} 
 
export default ProductsContextProvider;