import React, { useRef }  from 'react';
const FormField = ({ label, type, name, placeholder, required }) => {
  let ref = useRef();

  const handleFocus = () => {
    ref.classList.toggle('hover');
  }

  return (
    <div ref={ element => { ref = element }}>
      <label htmlFor={name}>{placeholder}</label>
      <input id={name} onFocus ={() => handleFocus() } onBlur = {() => handleFocus() } name={name} type={type} required />
      
    </div>
  );
};

export default FormField;