import React, { useContext } from "react";
import { CartContext } from "../../../contexts/CartContext";

import { formatNumber } from "../../utils/utils";
import IconPlus from "../../../assets/img/icons/plus.svg";
import IconTrash from "../../../assets/img/icons/trash.svg";
const { REACT_APP_SERVER_MEDIA } = process.env;

const CartItem = ({ product }) => {
    const { increase, removeProduct } = useContext(CartContext);
    const { title, attachmentProducts, quantity,  price } = product;
    
    return (
        <div className="row align-items-center pt-5 pb-5 m-0">
            <div className="col-3 cart__img text-center">
                <img
                    alt={title.rendered}
                    src={ (REACT_APP_SERVER_MEDIA)+attachmentProducts[0].image }
                    className="responsive-image"
                />
            </div>
            <div className="col-4">
                <h2 className="cart__h2 m-0">{title.rendered}</h2>
                <p className="cart__price price m-0">{formatNumber(price)} </p>
            </div>
            <div className="col-2 p-2 text-center">
                <p className="small m-0">Quantité : {quantity}</p>
            </div>
            <div className="col-3 text-right">
                <button
                    onClick={() => increase(product)}
                    className="btn btn-primary  btn_transparent no_border p-0"
                >
                    <img src={IconPlus} alt="Ajouter" width="20px" />
                </button>
                <button
                    onClick={() => removeProduct(product)}
                    className="btn btn_transparent no_border ml-2 p-0"
                >
                    <img src={IconTrash} alt="Retirer" width="20px" />
                </button>
            
            </div>
        </div>
    );
};

export default CartItem;
