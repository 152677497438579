import React, { useContext } from 'react';
import Img from '../../../assets/img/icons/cartbtn.svg';
import { CartContext } from "../../../contexts/CartContext";
const { REACT_APP_CART } = process.env;

const CartBtn = () => {
    const {itemCount} = useContext(CartContext);

    return(
        <div className="cart__btn btn d-flex flex-column align-items-center no_border">
                <img className="responsive-img" src={ Img } alt="" />
                { REACT_APP_CART }
                <span>{ itemCount }</span>
        </div>
    )
}
export default CartBtn;