import { useEffect, useState } from 'react';
    
export default function useFetch(url) {
    // STATE
    const [data, setData] = useState(null);

    // USEFFECT 
    // (wait while loading fetched data)
    useEffect(() => {
        // Main fetch function

        fetch(url)
            .then(response => response.json())
            .then(json => setData(json));            

    }, [url]); // wait url variable

    // Return data
    return data;
}
