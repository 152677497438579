import React, { useEffect, useState, lazy } from 'react';
import { Link, Route, Switch } from "react-router-dom";
// import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";

const Home = lazy(() => import('./views/Home/'));
const Products = lazy(() => import('./views/Products'));
const Product = lazy(() => import('./views/Product'));
const Page = lazy(() => import('./views/Page'));

const { REACT_APP_RGPD1, REACT_APP_RGPD2 } = process.env;

const Routes = ({ location }, ...props) => {
    const [isCookieConsent, setIsCookieConsent] = useState(false);
    useEffect(() => {
        isCookieConsent &&
            setTimeout(() => {
                // ReactGA.initialize("UA-184902841-1");
                // ReactGA.pageview(location.pathname + location.search);
            }, 1000);
    }, [location, isCookieConsent]);

    return(
        <>
        <Switch >
            <Route exact path="/" component={Home}/>
            <Route path="/products" component={Products}/>
            <Route path="/product/:id" component={Product}/>
            <Route path="/about" render={props => <Page slug="about" />} />
            <Route path="/mentions-legales" render={props => <Page slug="mentions-legales" />} />
            <Route path="/gestion-des-cookies" render={props => <Page slug="gestion-des-cookies" />} />
            <Route path="/conditions-generales-de-vente" render={props => <Page slug="conditions-generales-de-vente" />} />
            
        </Switch>
        <CookieConsent
                onAccept={() => {
                    setIsCookieConsent(true);
                }}
                location="bottom"
                declineButtonText="Je refuse"
                buttonText="J'accepte"
                cookieName="cookieAccept"
                style={{ background: "#2B373B" }}
                buttonStyle={{
                    background: "#000",
                    color: "white",
                    fontSize: "13px",
                }}
                declineButtonStyle={{
                    background: "#000",
                    color: "white",
                    fontSize: "13px",
                }}
                enableDeclineButton
                expires={150}
            >
                { REACT_APP_RGPD1 }<br />
                <span style={{ fontSize: "12px" }}>{ REACT_APP_RGPD2 }{" "}
                    <Link
                        style={{
                            fontSize: "12px",
                            marginBottom: "3px",
                            color: "white",
                        }}
                        className="btn btn_link btn_transparent d-inline"
                        to="mentions-legales">
                        Mentions légales
                    </Link>
                </span>
            </CookieConsent>
            </>    
    );
}
export default Routes;