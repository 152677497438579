import React from 'react';
import Icon from '../../../assets/img/icons/warning.svg';

const CheckoutError = (props) => {
    return (
        <p className="error text-center">
            <img className="mr-1" width="20px" src={ Icon } alt=""/> <br/><b>Erreur :</b><br/>
            { props.children }
            
        </p>
    );
}
export default CheckoutError;