import React, { Suspense } from 'react';
import { BrowserRouter } from "react-router-dom";


import Nav from './shared/layouts/Nav';
import Cart from './shared/components/Cart/Cart';
import Footer from './shared/layouts/Footer';
import Routes from './Routes';

const { PUBLIC_URL } = process.env;

const App = () => {
  return (
    <Suspense fallback="">
        <BrowserRouter basename={ PUBLIC_URL }>
          <Nav />
          <Routes />
          <Cart  />
          <Footer />
        </BrowserRouter>
    </Suspense>
  );
}

export default App;